import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"



const IndexPage = ({data}) => {
  return (
        <Layout>
          <SEO title="Home" />
          <div className="content-box clearfix">

          {data.allMicrocmsBlog.edges.map(edge => {
      const blog = edge.node

        return (
          <React.Fragment key={blog.id}>
                <article className="post">
                <Link to={`/${blog.category[0].toLowerCase()}/${blog.id}`}>
                <div className="post-content">
                <h2 className="post-title">
                      {blog.title}
                </h2>
                    <p>{blog.category}</p>
                      {/* <img
                        src={blog.pict.url}
                        width={160}
                        height={110}
                        alt="pict画像"
                      /> */}
                      {/* <p
                        dangerouslySetInnerHTML={{
                          __html: `${blog.body}`,
                        }}
                      ></p> */}
                      <p
                        dangerouslySetInnerHTML={{
                          __html: `${blog.description}`,
                        }}
                      ></p>
                      <span className="post-date">
                          {blog.createdAt}
                      </span>
                    </div>
                    </Link>
                </article>
        </React.Fragment>

        )
    })}
          </div>
        </Layout>
  )
}


export default IndexPage

export const query = graphql`
 {
    allMicrocmsBlog(
     sort: { fields: [createdAt], order: DESC }
   ) {
     edges {
       node {
         id
         title
         category
         description
         createdAt(formatString: "YYYY年MM月DD日")
       }
     }
   }
 }
`